<template>
    <div>
        <v-tooltip
                v-for="subitem in items"
                :key="subitem.id"
                top max-width="500" open-delay="300">
            <template v-slot:activator="{ on }">{{ console.log("Subitem from Chips Component ", subitem) }}
                <v-chip v-on="on">
                    <v-avatar
                            left
                            :class="calculateDynamicColor(subitem)">
                            <span>{{subitem.level}}</span>
                    </v-avatar>
                    {{ subitem.language?.name || subitem.name }}
                </v-chip>
            </template>
            <div>
                <div><b>{{$t('i18n_level')}}:</b> {{subitem.language?.rating?.allowedValues[subitem.level] || subitem.rating.allowedValues[subitem.level]}}</div>
                <div><b>{{$t('i18n_relevance')}}:</b> {{ subitem.language?.relevance || subitem.relevance}}</div>
                <div v-if="subitem.description"><b>Beschreibung:</b> {{ subitem.language?.rating?.description || subitem.rating.description }}</div>
            </div>
        </v-tooltip>
    </div>
</template>

<script>
    import calculateDynamicColor from "../../utils/color.utils";

    export default {
        props: {
            items: Array
        },
        methods:{
            calculateDynamicColor
        }
    }
</script>
